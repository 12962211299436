import axios from "axios";
import { API_URL } from "../../../consts";
import { getAxiosConfig } from "../../../services";

const duplicateProject = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}/projeto/project/${id}/duplicate_project`,
      null,
      getAxiosConfig()
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao deletar projeto:", error);
    throw error;
  }
};

export default duplicateProject;
