import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/SelectInput";
import { Form } from "informed";
import { API_URL } from "../../../consts";
import Axios from "axios";
import PieChart from "../../../components/v2/graficos/PieChart";
import DoughnutChart from "../../../components/v2/graficos/DoughnutChart";
import HorizontalBarChart from "../../../components/v2/graficos/HorizontalBarChart";
import { toast } from "react-toastify";
import ExportExcelButton from "../../../components/ExportExcelButton";
import { get } from "lodash";

function FollowGeralTable() {
  const [data, setData] = useState([]);
  const [dataParams, setDataParams] = useState([]);
  const [projetos, setProjetos] = useState([]);

  const configDataPie = () => {
    return {
      labels: ["Concluído", "Pendente"],
      datasets: [
        {
          label: "# of Votes",
          data: [
            ((data.nr_redacoes_concluidas / data.nr_redacoes) * 100).toFixed(2),
            ((data.nr_redacoes_em_andamento / data.nr_redacoes) * 100).toFixed(
              2
            ),
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
          ],
          borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
          borderWidth: 1,
        },
      ],
    };
  };

  const configDataHorizontalBarCorrecao = () => {
    return {
      data: {
        labels: ["Concluído", "Pendente", "Total"],
        datasets: [
          {
            data: [
              data.nr_redacoes_concluidas,
              data.nr_redacoes - data.nr_redacoes_concluidas,
              data.nr_redacoes,
            ],
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  };

  const configDataDoughnut = () => {
    return {
      labels: [
        "Sem Problemas",
        "Brancas",
        "Insuficientes",
        // 'Outras',
      ],
      datasets: [
        {
          label: "# of Votes",
          data: [
            data.nr_redacoes_concluidas -
              data.nr_redacoes_branco -
              data.nr_redacoes_texto_insuficiente,
            data.nr_redacoes_branco,
            data.nr_redacoes_texto_insuficiente,
            0,
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(255, 99, 132, 0.2)",
            // 'rgba(0, 255, 0, 0.2)',
          ],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(255, 99, 132, 1)",
            // 'rgba(0, 255, 0, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const configDataRed = () => {
    return {
      labels: [
        "Redações Corrigidas",
        "Redações Pendentes",
      ],
      datasets: [
        {
          label: "# of Votes",
          data: [
            data.nr_redacoes_concluidas,
            data.nr_redacoes - data.nr_redacoes_concluidas,
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(255, 99, 132, 0.2)",
            // 'rgba(0, 255, 0, 0.2)',
          ],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(255, 99, 132, 1)",
            // 'rgba(0, 255, 0, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const configDataHorizontalBar = () => {
    return {
      data: {
        labels: ["1º Fila", "2º Fila", "3º Fila", "4º Fila", "Recursos"],
        datasets: [
          {
            data: [
              data.nr_correcoes_fila_1,
              data.nr_correcoes_fila_2,
              data.nr_correcoes_fila_3,
              data.nr_correcoes_fila_4_quarta,
              data.nr_correcoes_fila_4_recurso,
            ],
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(0, 255, 0, 0.2)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(0, 255, 0, 1)",
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  };

  const fetchData = async (projetoId) => {
    try {
      const params = {
        projeto: projetoId,
      };
      const res = await Axios.get(
        `${API_URL}/v2/relatorios/acompanhamento-correcoes-v2/acompanhamento-geral/cria_relatorio_v2`,
        { params }
      );
      setData(res.data[0]);
      setDataParams({ ...params });
      if (res.data.length === 0) {
        toast.error("Não há dados para o projeto selecionado.");
      }
    } catch (err) {
      toast.error("Erro ao buscar os dados de relátorio, tente novamente.");
      setData([]);
    }
  };

  const fetchProjetos = async () => {
    try {
      const res = await Axios.get(`${API_URL}/projeto/all`);
      setProjetos(
        res.data.map((x) => ({
          value: x.id,
          label: x.descricao,
        }))
      );
    } catch (err) {
      toast.error("Erro ao buscar projetos, tente novamente.");
    }
  };

  useEffect(() => {
    if (data) {
      configDataPie();
      configDataDoughnut();
      configDataHorizontalBar();
    }
  }, [data]);

  useEffect(() => {
    fetchProjetos();
  }, []);

  const handleSelectProjeto = (e) => {
    const projetoId = e.target.value;
    fetchData(projetoId);
  };

  const exportData = {
    url: `${API_URL}/projeto/relatorio-notas-geral`,
    filename: `relatorio_notas_${get(data, "projeto")}.xlsx`,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          fontFamily: "pulic-sans",
          fontSize: "38px",
          marginTop: "10px",
        }}
      >
        Acompanhamento Geral
      </h1>
      <Form
        style={{
          display: "flex",
          width: "100%",
          height: "120px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SelectInput
          label="Projeto"
          field="projeto"
          options={projetos}
          onChange={(e) => handleSelectProjeto(e)}
        />
      </Form>
      {data && data.length !== 0 && (
        <>
          <div className="container mt-4">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 mb-4 d-flex justify-content-center">
              <DoughnutChart
                data={configDataRed()}
                titleChart="Andamento do Processo"
                width='100%'
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4 d-flex justify-content-center">
              <DoughnutChart
                data={configDataDoughnut()}
                titleChart="Categoria de Redação"
                width='100%'

              />
            </div>
            <div className="col-12 col-md-12 col-lg-6 mb-4 d-flex justify-content-center">
              <HorizontalBarChart
                data={configDataHorizontalBar().data}
                titleChart="Correções Pendentes"
                options={configDataHorizontalBar().options}
                width='100%'
              />
            </div>
          </div>
          </div>
          <div style={{ overflow: 'auto', width: '100%' }}>
            <h1
              style={{
                fontFamily: 'pulic-sans',
                fontSize: '20px',
                textAlign: 'center',
                margin: '25px 0px',
              }}
            >
              Andamento do Processo
            </h1>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: '100%', height: '100%' }}
            >
              <div className="table-responsive" style={{ width: '90%' }}>
                <table className="table table-striped">
                  <thead>
                    <tr
                      style={{
                        fontFamily: 'pulic-sans',
                      }}
                    >
                      <th scope="col">Projeto</th>
                      <th scope="col">Etapa de Ensino</th>
                      <th scope="col">Total de Redações</th>
                      <th scope="col">Total Redação Corrigidas</th>
                      <th scope="col">Total sem Problemas</th>
                      <th scope="col">Total em Branco</th>
                      <th scope="col">Total Insuficientes</th>
                      <th scope="col">% Concluídas</th>
                      <th scope="col">% Pendente</th>
                      <th scope="col">Relatório de notas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.projeto}</td>
                      <td>{data.etapa_ensino}</td>
                      <td>{data.nr_redacoes}</td>
                      <td>{data.nr_redacoes_concluidas}</td>
                      <td>
                        {data.nr_redacoes_concluidas -
                          data.nr_redacoes_branco -
                          data.nr_redacoes_texto_insuficiente}
                      </td>
                      <td>{data.nr_redacoes_branco}</td>
                      <td>{data.nr_redacoes_texto_insuficiente}</td>
                      <td>
                        {(
                          (data.nr_redacoes_concluidas / data.nr_redacoes) *
                          100
                        ).toFixed(2)}
                      </td>
                      <td>
                        {(
                          100 -
                          (data.nr_redacoes_concluidas / data.nr_redacoes) * 100
                        ).toFixed(2)}
                      </td>
                      <td>
                        <ExportExcelButton
                          url={exportData.url}
                          filename={exportData.filename}
                          params={dataParams}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mt-4" style={{ overflow: 'auto', width: '100%' }}>
          <h1
            style={{
              fontFamily: 'pulic-sans',
              fontSize: '20px',
              textAlign: 'center',
              margin: '25px 0px',
            }}
          >
            Andamento das Correções
          </h1>
          <div className="d-flex justify-content-center">
            <div className="table-responsive" style={{ width: '90%' }}>
              <table className="table table-striped">
                <thead>
                  <tr
                    style={{
                      fontFamily: 'pulic-sans',
                    }}
                  >
                    <th scope="col">Total de Correções</th>
                    <th scope="col">1º Correção Concluída</th>
                    <th scope="col">1º Correções Pendentes</th>
                    <th scope="col">2º Correção Concluída</th>
                    <th scope="col">2º Correções Pendentes</th>
                    <th scope="col">3º Correção Concluída</th>
                    <th scope="col">3º Correções Pendentes</th>
                    <th scope="col">4º Correção Concluída</th>
                    <th scope="col">4º Correções Pendentes</th>
                    <th scope="col">Recursos Concluídos</th>
                    <th scope="col">Recursos Pendentes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.nr_correcoes}</td>
                    <td>{data.nr_1_correcao_concluida}</td>
                    <td>{data.nr_correcoes_fila_1}</td>
                    <td>{data.nr_2_correcao_concluida}</td>
                    <td>{data.nr_correcoes_fila_2}</td>
                    <td>{data.nr_3_correcao_concluida}</td>
                    <td>{data.nr_correcoes_fila_3}</td>
                    <td>{data.nr_4_correcao_concluida}</td>
                    <td>{data.nr_correcoes_fila_4_quarta}</td>
                    <td>{data.nr_recurso_concluida}</td>
                    <td>{data.nr_correcoes_fila_4_recurso}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
}

export default FollowGeralTable;
