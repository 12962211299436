import React from "react";
import {
  StyledBackgroundInput,
  StyledBoxInput,
  StyledFlexRow,
  StyledInputNotas,
  StyledInputText,
  StyledInputTextObservacao,
  StyledInputTextObservacaoDiv,
  StyledLabel,
  StyledLinhaDivisoria,
  StyledTagCreateProject,
  StyledTextTag,
  FormControl,
  FormControlLabel,
  Switch,
} from "../styles/StyledCreateProjectCorrecao";
import Chip from "../../../components/Chip";

const IOSSwitch = (props) => (
  <Switch
    style={{
      width: 42,
      height: 26,
      padding: 0,
      marginLeft: 5,
    }}
    {...props}
    inputProps={{ "aria-label": "iOS design" }}
    color="primary"
  />
);

const SkillsForProject = ({
  skillData,
  setSkillData,
  handleDeleteSkill,
  index,
  data,
}) => {

const handleNotaChange = (event, index) => {
  const { id, value } = event.target;
  const updatedSkillData = [...skillData];
  const notaIndex = parseInt(id.replace("nota", ""), 10);

  updatedSkillData[index] = {
    ...updatedSkillData[index],
    [`nota${notaIndex}`]: value,
  };

  setSkillData(updatedSkillData);
};

  const handleChange = (event) => {
    const { id, value } = event.target;
    const updatedSkillData = [...skillData];

    if (id === "countNotas") {
      const newCountNotas = parseInt(value, 10) || 0;
      updatedSkillData[index].countNotas = newCountNotas;
    } else if (id === "notaMaxima") {
      const numericValue = value === "" ? "" : Number(value);
      updatedSkillData[index] = {
        ...updatedSkillData[index],
        [id]: numericValue,
      };
    } else {
      updatedSkillData[index] = {
        ...updatedSkillData[index],
        [id]: value,
      };
    }

    setSkillData(updatedSkillData);
  };

  const handleChangeBoolean = (event) => {
    const updatedSkillData = [...skillData];

    updatedSkillData[index] = {
      ...updatedSkillData[index],
      [event.target.name]: event.target.checked,
    };

    if (
      event.target.name === "padronizarObservacao" &&
      event.target.checked === false
    ) {
      updatedSkillData[index] = {
        ...updatedSkillData[index],
        padronizarObservacao: false,
        observacaoApontamentos: [],
      };
    }

    setSkillData(updatedSkillData);
  };

  const handleDelete = (removeIndex) => {
    const newArray = skillData[index].observacaoApontamentos.filter(
      (value, index) => {
        if (index != removeIndex) {
          return value;
        }
      }
    );
    const updatedSkillData = [...skillData];
    updatedSkillData[index] = {
      ...updatedSkillData[index],
      observacaoApontamentos: newArray,
    };
    setSkillData(updatedSkillData);
  };

  const handleObservacaoChange = (event) => {
    const value = event.target.value.trim();

    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();

      if (value !== "") {
        const updatedSkillData = [...skillData];

        if (
          !Array.isArray(updatedSkillData[index].observacaoApontamentos)
        ) {
          updatedSkillData[index].observacaoApontamentos = [];
        }

        updatedSkillData[index] = {
          ...updatedSkillData[index],
          observacaoApontamentos: [
            ...updatedSkillData[index].observacaoApontamentos,
            value,
          ],
        };
        setSkillData(updatedSkillData);
        event.target.value = "";
      }
    }
  };
  return (
    <>
      <StyledTagCreateProject style={{ color: "white" }}>
        <StyledTextTag>Cadastrar competências</StyledTextTag>
        <div
          style={{ cursor: "pointer", color: "white" }}
          onClick={() => handleDeleteSkill(index)}
          color="white"
        >
          x
        </div>
      </StyledTagCreateProject>
      <FormControl style={{ width: "100%" }}>
        <StyledFlexRow>
          <StyledBoxInput>
            <StyledLabel for="competencia">
              Competência/Rúbrica
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <StyledInputText
              type="text"
              id="competencia"
              placeholder="Insira aqui uma competência"
              onChange={handleChange}
              value={data.competencia}
              required
            />
          </StyledBoxInput>

          <StyledBoxInput style={{ width: "50%" }}>
            <StyledLabel for="showPosition">
              Posição de exibição
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <StyledInputText
              type="number"
              id="showPosition"
              placeholder="Digite"
              onChange={handleChange}
              value={data.showPosition}
              required
            />
          </StyledBoxInput>

          <StyledBoxInput style={{ width: "50%" }}>
            <StyledLabel for="notaMaxima">
              Nota máxima
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <StyledInputText
              type="number"
              id="notaMaxima"
              placeholder="Digite"
              onChange={handleChange}
              value={data.notaMaxima}
              required
            />
          </StyledBoxInput>
        </StyledFlexRow>
        <StyledFlexRow>
          <StyledBoxInput style={{ width: "50%" }}>
            <StyledLabel for="countNotas">
              Quantas notas para a competência
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <StyledInputText
              type="number"
              id="countNotas"
              placeholder="Digite"
              onChange={handleChange}
              value={data.countNotas}
              required
            />
          </StyledBoxInput>

          <StyledBoxInput>
            <StyledLabel>
              Notas<span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <StyledBackgroundInput>
              {Array(data.countNotas)
                .fill(null)
                .map((_, notaIndex) => (
                  <StyledInputNotas
                    key={notaIndex}
                    type="number"
                    id={`nota${notaIndex}`}
                    onChange={(event) => handleNotaChange(event, index)}
                    value={data[`nota${notaIndex}`] || ""}
                    required
                  />
                ))}
            </StyledBackgroundInput>
          </StyledBoxInput>

          <StyledBoxInput style={{ width: "50%" }}>
            <StyledLabel>
              Exibir proposta de <br />
              intervenção?
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={Boolean(data.exibirPropostaIntervencao)}
                  onChange={handleChangeBoolean}
                  name="exibirPropostaIntervencao"
                  value={data.exibirPropostaIntervencao}
                  required
                />
              }
            />
          </StyledBoxInput>
        </StyledFlexRow>

        <StyledFlexRow style={{ width: "70%" }}>
          <StyledBoxInput>
            <StyledLabel>
              Padronizar observação <br />
              para os apontamentos?
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={Boolean(data.padronizarObservacao)}
                  onChange={handleChangeBoolean}
                  name="padronizarObservacao"
                  value={data.padronizarObservacao}
                  required
                />
              }
            />
          </StyledBoxInput>
          <StyledBoxInput>
            <StyledLabel>
              Digitar observação <br />
              durante apontamento?
              <span style={{ color: "red", lineHeight: "0" }}>*</span>
            </StyledLabel>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={Boolean(data.digitarObservacao)}
                  onChange={handleChangeBoolean}
                  name="digitarObservacao"
                  value={data.digitarObservacao}
                  required
                />
              }
            />
          </StyledBoxInput>
        </StyledFlexRow>
        <StyledFlexRow>
          {data.padronizarObservacao && (
            <StyledBoxInput>
              <StyledLabel for="observacaoApontamentos">
                Observações dos apontamentos
                <span style={{ color: "red", lineHeight: "0" }}>*</span>
              </StyledLabel>
              <StyledInputTextObservacaoDiv>
                {data &&
                  data.observacaoApontamentos &&
                  data.observacaoApontamentos.map((value, index) => (
                    <>
                      <Chip
                        key={value}
                        size="small"
                        label={value}
                        onDelete={() => handleDelete(index)}
                        color="primary"
                      />
                    </>
                  ))}
                <StyledInputTextObservacao
                  type="text"
                  id="observacaoApontamentos"
                  placeholder="Insira aqui uma observação"
                  onBlur={handleObservacaoChange}
                  onKeyDown={handleObservacaoChange}
                />
              </StyledInputTextObservacaoDiv>
            </StyledBoxInput>
          )}
        </StyledFlexRow>

        <StyledLinhaDivisoria />
      </FormControl>
    </>
  );
};

export default SkillsForProject;
