export const validateSkillsData = (skillsData) => {
  const errors = [];

  // 1. Validar campos obrigatórios
  skillsData.forEach((skill, index) => {
    if (!skill.competencia || skill.competencia.trim() === "") {
      errors.push(
        `O campo 'Competência/Rúbrica' é obrigatório no item ${index + 1}.`
      );
    }

    if (!skill.showPosition || isNaN(skill.showPosition)) {
      errors.push(
        `O campo 'Posição de Exibição' é obrigatório e deve ser um número no item ${
          index + 1
        }.`
      );
    }

    if (!skill.notaMaxima || isNaN(skill.notaMaxima)) {
      errors.push(
        `O campo 'Nota Máxima' é obrigatório e deve ser um número no item ${
          index + 1
        }.`
      );
    }

    if (!skill.countNotas || isNaN(skill.countNotas)) {
      errors.push(
        `O campo 'Quantas Notas para a Competência/Rúbrica' é obrigatório e deve ser um número no item ${
          index + 1
        }.`
      );
    }
  });

  // 2. Validar posições únicas
  const positions = [];
  skillsData.forEach((skill, index) => {
    const position = skill.showPosition;

    if (positions.includes(position)) {
      const repeatedIndex = positions.indexOf(position);
      errors.push(
        `A posição de exibição '${position}' se repete nos itens ${
          repeatedIndex + 1
        } e ${index + 1}.`
      );
    }
    positions.push(position);
  });

  // 3. Validar campos de notas
  skillsData.forEach((skill, index) => {
    for (let i = 0; i < skill.countNotas; i++) {
      const notaKey = `nota${i}`;

      if (!skill[notaKey] || isNaN(skill[notaKey])) {
        errors.push(
          `Necessário preencher todas as notas selecionadas no item ${
            index + 1
          }.`
        );
      }

      if (skill[notaKey] < 0) {
        errors.push(
          `Notas não podem receber números negativos no item ${index + 1}.`
        );
      }
    }
  });

  return errors;
};
