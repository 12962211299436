import axios from "axios";
import { API_URL } from "../../../consts";
import { getAxiosConfig } from "../../../services";

const deleteProject = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/projeto/project/${id}`, getAxiosConfig());
        return response.data;
    } catch (error) {
        console.error("Erro ao deletar projeto:", error);
        throw error;
    }
};

export default deleteProject;
