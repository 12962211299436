import { toast } from "react-toastify";

export const validateProjectData = (projectData) => {
  const errors = {};

  const camposObrigatorios = {
    max_correcoes_dia: "Quantidade máxima de correções por dia",
    titulo: "Título do Projeto",
    fila_prioritaria: "Quantidade máxima de correções em uma redação",
    limite_nota_final: "Limite de discrepância entre as notas de correção",
    nota_inicial: "Nota inicial da redação",
    descricao: "Descrição",
  };

  // Verificação de campos obrigatórios
  Object.keys(camposObrigatorios).forEach((campo) => {
    if (!projectData[campo] || projectData[campo].toString().trim() === "") {
      errors[campo] = `O campo '${camposObrigatorios[campo]}' é obrigatório.`;
    }
  });

  // Validação de fila prioritaria
  if (projectData.fila_prioritaria > 4) {
    errors.fila_prioritaria =
      "A quantidade máxima de correções em uma redação não pode ser superior a 4.";
  }

  // Validação da data de início e término (geral)
  const validateDataRange = (
    inicio,
    termino,
    campoInicio,
    campoTermino,
    nome
  ) => {
    if (inicio && termino && new Date(inicio) >= new Date(termino)) {
      errors[
        campoInicio
      ] = `A data de início de ${nome} deve ser anterior à data de término.`;
    }
  };

  // Validação para as datas do projeto (opcional)
  if (projectData.data_inicio || projectData.data_termino) {
    validateDataRange(
      projectData.data_inicio,
      projectData.data_termino,
      "data_inicio",
      "data_termino",
      "correção"
    );
  }

  // Validação para vista da redação
  if (projectData.configurarVistaRedacao) {
    if (!projectData.data_inicio_vista || !projectData.data_termino_vista) {
      errors.configurarVistaRedacao =
        "Se habilitado, a configuração do tempo de vista da redação requer data de início e término.";
    } else {
      validateDataRange(
        projectData.data_inicio_vista,
        projectData.data_termino_vista,
        "data_inicio_vista",
        "data_termino_vista",
        "vista da redação"
      );
    }
  }

  // Validação para utilização de recurso
  if (projectData.utilizacaoDeRecurso) {
    if (!projectData.data_inicio_recurso || !projectData.data_termino_recurso) {
      errors.utilizacaoDeRecurso =
        "Se o recurso estiver habilitado, é necessário inserir a data de início e término.";
    } else {
      validateDataRange(
        projectData.data_inicio_recurso,
        projectData.data_termino_recurso,
        "data_inicio_recurso",
        "data_termino_recurso",
        "recurso"
      );
    }
  }

  // Exibir erros no toast e retornar erros
  if (Object.keys(errors).length > 0) {
    Object.values(errors).forEach((msg) => toast.error(msg));
  }

  return errors;
};
