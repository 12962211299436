import React, {useEffect, useState} from "react";
import { withRouter } from "react-router";
import {
  StyledPage,
  StyledContainer,
  StyledBoxButtons,
  StyledTagCreateProject,
  StyledTextTag,
  StyledFlexRow,
  StyledBoxInput,
  StyledLabel,
  StyledInputText,
  Button,
  FormControl,
  Switch,
  FormControlLabel,
} from "../styles/StyledCreateProjectCorrecao";
import { getAxiosConfig } from "../../../services";
import { API_HOST } from "../../../consts";
import Axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { validateProjectData } from "./validations/projectsValidations";

const IOSSwitch = (props) => (
  <Switch
    style={{
      width: 42,
      height: 26,
      padding: 0,
      marginLeft: 5,
    }}
    {...props}
    inputProps={{ "aria-label": "iOS design" }}
    color="primary"
  />
);

const SettingsProjectCorrecao = ({
  projectData,
  setProjectData,
  setIndexPage,
  setProjectAndGrade,
  loading,
  setLoading,
}) => {

  useEffect(() => {
    const url = window.location.pathname.split("/");
    const lastSegment = url[url.length - 1];

    if (lastSegment !== "configurar-projeto") {
      const fetchProjectData = async () => {
        try {
          const response = await Axios.get(
            `${API_HOST}/projeto/project/${lastSegment}`,
            getAxiosConfig()
          );
          const data = response.data;

          const formattedData = {
            ...data,
            data_inicio: data.data_inicio ? data.data_inicio.split("T")[0] : "",
            hora_inicio:
              data.data_inicio && data.data_inicio.split("T")[1]
                ? data.data_inicio.split("T")[1].slice(0, 5)
                : "",
            data_termino: data.data_termino
              ? data.data_termino.split("T")[0]
              : "",
            hora_termino:
              data.data_termino && data.data_termino.split("T")[1]
                ? data.data_termino.split("T")[1].slice(0, 5)
                : "",
            data_inicio_vista: data.data_inicio_vista
              ? data.data_inicio_vista.split("T")[0]
              : "",
            hora_inicio_vista:
              data.data_inicio_vista && data.data_inicio_vista.split("T")[1]
                ? data.data_inicio_vista.split("T")[1].slice(0, 5)
                : "",
            data_termino_vista: data.data_termino_vista
              ? data.data_termino_vista.split("T")[0]
              : "",
            hora_termino_vista:
              data.data_termino_vista && data.data_termino_vista.split("T")[1]
                ? data.data_termino_vista.split("T")[1].slice(0, 5)
                : "",
            data_inicio_recurso: data.data_inicio_recurso
              ? data.data_inicio_recurso.split("T")[0]
              : "",
            data_termino_recurso: data.data_termino_recurso
              ? data.data_termino_recurso.split("T")[0]
              : "",
            nota_inicial: data.nota_inicial || 0,
            configurarVistaRedacao: data.configurar_vista_redacao || false,
            utilizacaoDeRecurso: data.utilizacao_de_recurso || false,
            limite_nota_final: data.limite_nota_final 
              ? parseInt(data.limite_nota_final, 10) 
              : "",
            limite_nota_competencia: parseInt(data.limite_nota_competencia),
          };

          setProjectData(formattedData);
        } catch (error) {
          console.error(error);
          toast.error("Erro ao carregar os dados do projeto.");
        } finally {
          setLoading(false);
        }
      };

      fetchProjectData();
    } else {
      setLoading(false);
    }
  }, []);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setProjectData({
      ...projectData,
      [id]: value,
    });
  };

  const handleChangeBoolean = (event) => {
    setProjectData({
      ...projectData,
      [event.target.name]: event.target.checked,
    });

    if (
      event.target.name === "configurarVistaRedacao" &&
      event.target.checked === false
    ) {
      setProjectData({
        ...projectData,
        data_inicio_vista: undefined,
        data_termino_vista: undefined,
        hora_inicio_vista: undefined,
        hora_termino_vista: undefined,
        configurarVistaRedacao: false,
      });
      }

    if (
      event.target.name === "utilizacaoDeRecurso" &&
      event.target.checked === false
    ) {
      setProjectData({
        ...projectData,
        data_inicio_recurso: undefined,
        data_termino_recurso: undefined,
        utilizacaoDeRecurso: false,
      });
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    window.history.back();
  };

  const formatDateTime = (date, time) => {
  if (date && time && date !== "" && time !== "") {
    return `${date}T${time}`;
  }
  return null;
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Validar os dados antes de prosseguir
    const errors = validateProjectData(projectData);
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      const url = window.location.pathname.split("/");
      const lastSegment = url[url.length - 1];

      const id = lastSegment === "configurar-projeto" ? null : lastSegment;

      const params = {
        ...projectData,
        id: id,
        titulo: projectData.titulo,
        descricao: projectData.descricao,
        data_termino: projectData.data_termino + "T" + projectData.hora_termino,
        data_inicio: projectData.data_inicio + "T" + projectData.hora_inicio,
        nota_inicial: projectData.nota_inicial,
        max_correcoes_dia: projectData.max_correcoes_dia,
        fila_prioritaria: projectData.fila_prioritaria,
        limite_nota_final: projectData.limite_nota_final,
        configurar_vista_redacao: projectData.configurarVistaRedacao,
        data_termino: formatDateTime(
          projectData.data_termino,
          projectData.hora_termino
        ),
        data_inicio: formatDateTime(
          projectData.data_inicio,
          projectData.hora_inicio
        ),
        data_inicio_recurso: projectData.data_inicio_recurso,
        data_termino_recurso: projectData.data_termino_recurso,
        utilizacao_de_recurso: projectData.utilizacaoDeRecurso,
        limite_nota_competencia: projectData.limite_nota_competencia,
        codigo: 1,
        fila_supervisor: 1,
        external_id: projectData.external_id || uuidv4(),
      };
      setProjectData(params);

      const result = await Axios.post(
        `${API_HOST}/projeto/projetos`,
        params,
        getAxiosConfig()
      );

      setProjectAndGrade(result);
      toast.success("Projeto criado/alterado com sucesso!");
      setIndexPage(2);
    } catch (error) {
      setLoading(false);

      if (error.response) {
        const errorMessage =
          error.response.data.error || "Ocorreu um erro inesperado.";
        const errorDetails = error.response.data.details
          ? `Detalhes: ${error.response.data.details}`
          : "";

        toast.error(`${errorMessage} ${errorDetails}`);
      } else {
        toast.error("Algo inesperado aconteceu.");
      }

      console.error(error);
    }
  };

  if (loading) {
    return (
      <Skeleton height={900} width={966} borderRadius={8} />
    );
  }

  
  return (
    <StyledPage>
      <StyledContainer>
        <StyledTagCreateProject>
          <StyledTextTag>Criar Projeto</StyledTextTag>
        </StyledTagCreateProject>

        <form onSubmit={handleSubmit}>
          <FormControl style={{ width: "100%" }}>
            <StyledFlexRow>
              <StyledBoxInput>
                <StyledLabel htmlFor="titulo">Título do Projeto</StyledLabel>
                <StyledInputText
                  type="text"
                  id="titulo"
                  placeholder="Insira aqui um título"
                  onChange={handleChange}
                  value={projectData && projectData.titulo}
                  required
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="descricao">
                  Descrição
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <StyledInputText
                  type="text"
                  id="descricao"
                  placeholder="Descreva"
                  onChange={handleChange}
                  value={projectData.descricao}
                  required
                />
              </StyledBoxInput>
            </StyledFlexRow>

            <StyledFlexRow>
              <StyledBoxInput>
                <StyledLabel htmlFor="data_inicio">
                  Data de início da correção
                </StyledLabel>
                <StyledInputText
                  type="date"
                  id="data_inicio"
                  placeholder="dd/mm/aaaa"
                  onChange={handleChange}
                  value={projectData.data_inicio}
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="hora_inicio">
                  Horário de início da correção
                </StyledLabel>
                <StyledInputText
                  type="time"
                  id="hora_inicio"
                  placeholder="__:__"
                  onChange={handleChange}
                  value={projectData.hora_inicio}
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="data_termino">
                  Data de término da correção
                </StyledLabel>
                <StyledInputText
                  type="date"
                  id="data_termino"
                  placeholder="dd/mm/aaaa"
                  onChange={handleChange}
                  value={projectData.data_termino}
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="hora_termino">
                  Horário de término da correção
                </StyledLabel>
                <StyledInputText
                  type="time"
                  id="hora_termino"
                  placeholder="__:__"
                  onChange={handleChange}
                  value={projectData.hora_termino}
                />
              </StyledBoxInput>
            </StyledFlexRow>

            <StyledFlexRow>
              <StyledBoxInput>
                <StyledLabel htmlFor="max_correcoes_dia">
                  Quantidade máxima de correções por dia
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <StyledInputText
                  type="number"
                  id="max_correcoes_dia"
                  placeholder="Digite"
                  onChange={handleChange}
                  value={projectData.max_correcoes_dia}
                  required
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="fila_prioritaria">
                  Quantidade máxima de correções em uma redação
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <StyledInputText
                  type="number"
                  id="fila_prioritaria"
                  placeholder="Digite"
                  onChange={handleChange}
                  value={projectData.fila_prioritaria}
                  required
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="limite_nota_final">
                  Limite de discrepância entre as notas de correção
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <StyledInputText
                  type="number"
                  id="limite_nota_final"
                  placeholder="Digite"
                  onChange={handleChange}
                  value={projectData.limite_nota_final}
                  required
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="limite_nota_competencia">
                  Limite de discrepância entre as notas por competência
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <StyledInputText
                  type="number"
                  id="limite_nota_competencia"
                  placeholder="Digite"
                  onChange={handleChange}
                  value={projectData.limite_nota_competencia}
                  required
                />
              </StyledBoxInput>

              <StyledBoxInput>
                <StyledLabel htmlFor="nota_inicial">
                  Nota inicial
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <StyledInputText
                  type="number"
                  id="nota_inicial"
                  placeholder="Digite"
                  onChange={handleChange}
                  value={projectData.nota_inicial}
                  required
                />
              </StyledBoxInput>
            </StyledFlexRow>

            <StyledFlexRow>
              <StyledBoxInput>
                <StyledLabel>
                  Configurar vista
                  <br />
                  de redação?
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={Boolean(projectData.configurarVistaRedacao)}
                      onChange={handleChangeBoolean}
                      name="configurarVistaRedacao"
                      value={projectData.configurarVistaRedacao}
                      required
                    />
                  }
                />
              </StyledBoxInput>
              <StyledBoxInput>
                <StyledLabel>
                  Utilização de
                  <br />
                  recurso?
                  <span style={{ color: "red", lineHeight: "0" }}>*</span>
                </StyledLabel>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={Boolean(projectData.utilizacaoDeRecurso)}
                      onChange={handleChangeBoolean}
                      name="utilizacaoDeRecurso"
                      value={projectData.utilizacaoDeRecurso}
                      required
                    />
                  }
                />
              </StyledBoxInput>

              {projectData && projectData.utilizacaoDeRecurso ? (
                <>
                  <StyledBoxInput>
                    <StyledLabel htmlFor="data_inicio_recurso">
                      Data de abertura de recurso
                      <span style={{ color: "red", lineHeight: "0" }}>*</span>
                    </StyledLabel>
                    <StyledInputText
                      type="date"
                      id="data_inicio_recurso"
                      placeholder="Digite"
                      onChange={handleChange}
                      value={projectData.data_inicio_recurso || ""}
                      required
                    />
                  </StyledBoxInput>

                  <StyledBoxInput>
                    <StyledLabel htmlFor="data_termino_recurso">
                      Data de fechamento de recurso
                      <span style={{ color: "red", lineHeight: "0" }}>*</span>
                    </StyledLabel>
                    <StyledInputText
                      type="date"
                      id="data_termino_recurso"
                      placeholder="Digite"
                      onChange={handleChange}
                      value={projectData.data_termino_recurso || ""}
                      required
                    />
                  </StyledBoxInput>
                </>
              ) : (
                <>
                  <StyledBoxInput />
                  <StyledBoxInput />
                </>
              )}
            </StyledFlexRow>

            {projectData && projectData.configurarVistaRedacao ? (
              <>
                <StyledTagCreateProject>
                  <StyledTextTag>
                    Configurar a visualização da redação pelo estudante
                  </StyledTextTag>
                </StyledTagCreateProject>

                <StyledFlexRow>
                  <StyledBoxInput>
                    <StyledLabel htmlFor="data_inicio_vista">
                      Data de início da vista
                    </StyledLabel>
                    <StyledInputText
                      type="date"
                      id="data_inicio_vista"
                      placeholder="dd/mm/aaaa"
                      onChange={handleChange}
                      value={projectData.data_inicio_vista || ""}
                      required
                    />
                  </StyledBoxInput>

                  <StyledBoxInput>
                    <StyledLabel htmlFor="hora_inicio_vista">
                      Horário de início da vista
                    </StyledLabel>
                    <StyledInputText
                      type="time"
                      id="hora_inicio_vista"
                      placeholder="__:__"
                      onChange={handleChange}
                      value={projectData.hora_inicio_vista || ""}
                      required
                    />
                  </StyledBoxInput>

                  <StyledBoxInput>
                    <StyledLabel htmlFor="data_termino_vista">
                      Data de término da vista
                    </StyledLabel>
                    <StyledInputText
                      type="date"
                      id="data_termino_vista"
                      placeholder="__:__"
                      onChange={handleChange}
                      value={projectData.data_termino_vista || ""}
                      required
                    />
                  </StyledBoxInput>

                  <StyledBoxInput>
                    <StyledLabel htmlFor="hora_termino_vista">
                      Horário de término da vista
                    </StyledLabel>
                    <StyledInputText
                      type="time"
                      id="hora_termino_vista"
                      placeholder="__:__"
                      onChange={handleChange}
                      value={projectData.hora_termino_vista || ""}
                      required
                    />
                  </StyledBoxInput>
                </StyledFlexRow>
              </>
            ) : (
              <>
                <StyledBoxInput />
                <StyledBoxInput />
                <StyledBoxInput />
              </>
            )}
            <StyledBoxButtons>
              <Button variant="outlined" color="primary" onClick={handleBack}>
                Voltar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Cadastrar competências
              </Button>
            </StyledBoxButtons>
          </FormControl>
        </form>
      </StyledContainer>
    </StyledPage>
  );
};

export default withRouter(SettingsProjectCorrecao);
