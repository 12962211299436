import PropTypes from "prop-types";
import React, { useContext } from "react";
import Redacao from "../../../containers/Redacao";
import BancoRedacoesContext from "../../../contexts/BancoRedacoesContext";
import HistoricoCorrecoes from "../historico_correcoes/HistoricoCorrecoes";
import { get } from "lodash";

const ImagemRedacaoExternaContent = ({ shapes }) => {
  const {
    redacao: { src, nota, situacao, show_situacao },
    fetchImagemError,
    historicoCorrecoes,
    observacoesCompetencia,
    descricaoCompetencia,
    fetchingHistoricoCorrecoes,
    simpleToken,
  } = useContext(BancoRedacoesContext);

  const propostaDeIntervencaoItens = [
    { label: "Agente:", id: "observacao_agente" },
    { label: "Ação:", id: "observacao_acao" },
    { label: "Modo:", id: "observacao_modo" },
    { label: "Efeito:", id: "observacao_efeito" },
    { label: "Detalhamento:", id: "observacao_detalhamento" },
  ];

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <HistoricoCorrecoes
          historicoCorrecoes={historicoCorrecoes}
          fetching={fetchingHistoricoCorrecoes}
          redacao={{ nota, situacao, show_situacao }}
          verHeader={false}
          forceShowSituacao={false}
          showNotaFinal
          showTipoCorrecao={false}
        />
      </div>
      <div className="col-md-8 col-sm-8 col-xs-8">
        <Redacao
          src={src}
          shapes={shapes}
          show={!fetchImagemError}
          fetchImagemError={fetchImagemError}
          isAbleToEdit={false}
          canRotacionar
          simpleToken={simpleToken}
        />
      </div>
      <div className="col-md-4 col-sm-4 col-xs-4">
        <h4>{descricaoCompetencia}</h4>
        <hr />
        {observacoesCompetencia ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {observacoesCompetencia.map((data) => (
              <>
                <span
                  style={{
                    marginTop: "20px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  {
                    propostaDeIntervencaoItens.filter(
                      (d) => d.id === data.title
                    )[0].label
                  }
                </span>
                <span style={{ marginTop: "6px", textAlign: "justify" }}>
                  {get(data, "content")}
                </span>
              </>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

ImagemRedacaoExternaContent.propTypes = {
  shapes: PropTypes.shape({
    id: PropTypes.number,
  }),
};

ImagemRedacaoExternaContent.defaultProps = {
  shapes: {},
};

export default ImagemRedacaoExternaContent;
