import { get, size } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import TableContext from "../../contexts/TableContext";
import { FooterTable } from "../../components";
import Table from "../../components/Table";
import { DropDownButton } from "./styles/StyledCreateProjectCorrecao";
import deleteProject from "./hooks/deleteProject";
import duplicateProject from "./hooks/duplicateProject";
import { toast } from "react-toastify";

const ProjectsList = ({history}) => {
  const { data: projects, loading, refetch } = useContext(TableContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = (id) => {
    history.push(`/configurar-projeto/${id}`);
  };

  const handleDelete = async (id) => {    
    try {
      setIsLoading(true);
      await deleteProject(id);
      await refetch();
      toast.success(`Projeto ${id} excluído com sucesso!`);      
    } catch (error) {
      toast.error("Erro ao deletar projeto");
      console.error("Erro ao deletar projeto:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDuplicate = async (id) => {
    try {
      setIsLoading(true);
      const newProject = await duplicateProject(id);
      toast.success(`Projeto duplicado com sucesso!`);
      history.push(`/configurar-projeto/${newProject.id}`);
    } catch (error) {
      toast.error("Erro ao duplicar projeto");
      console.error("Erro ao deletar projeto:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      accessor: "id",
      Header: "ID",
      width: 70,
      Footer: () => <FooterTable media="Total" />,
    },
    {
      accessor: "title",
      Header: "Título",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "description",
      Header: "Descrição",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "startDate",
      Header: "Data Início",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "endDate",
      Header: "Data Término",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "edit",
      width: 70,
      filterable: false,
      Footer: (row) => <FooterTable media={size(get(row, "data"))} />,
      Cell: (cell) => (
        <>
          <DropDownButton icon={<i className="fa fa-edit" />}>
            <button
              style={{
                color: "#888585",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
              onClick={() => handleEdit(get(cell, "original.id"))}
            >
              Editar
            </button>
            <button
              style={{
                color: "#888585",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
              onClick={() => handleDuplicate(get(cell, "original.id"))}
            >
              Duplicar
            </button>
            <button
              style={{
                color: "#888585",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
              onClick={() => handleDelete(get(cell, "original.id"))}
            >
              Excluir
            </button>
          </DropDownButton>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="page-container-border">
        <h1 className="text-title no-border">Projetos</h1>
        <hr className="m-0" />
        <div className="w-100 d-flex justify-content-end mt-2 mb-2">
          <button className="btn btn-primary shadow-sm rounded px-4 py-2 btn-custom">
            <Link
              className="text-center"
              to="/configurar-projeto"
              style={{
                display: "inline-block",
                width: "auto",
                textAlign: "center",
                color: "white",
              }}
            >
              Criar Projeto
            </Link>
          </button>
        </div>
          <Table
            className="m-2"
            columns={columns}
            data={projects}
            loading={loading && !isLoading}
          />
      </div>
    </div>
  );
};

export default withRouter(ProjectsList);