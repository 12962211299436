import React from "react";
import fetchProjectsData from "../hooks/fetchProjectsData";
import TableState from "../../../states/TableState";
import ProjectsList from "../ListProjects";

const TableProjects = () => (
  <TableState fetch={(params) => fetchProjectsData(params)}>
    <ProjectsList />
  </TableState>
);

export default TableProjects;
