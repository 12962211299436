import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_HOST } from "../../../consts";
import { getAxiosConfig } from "../../../services";
import { StyledEnunciado, Button, StyledBoxButtons, ExcludeSkillModal, ModalOverlay } from "../styles/StyledCreateProjectCorrecao";
import SkillsForProject from "./SkllsForProject";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { validateSkillsData } from "./validations/skillsValidations";

const CreateSkillsForProject = ({ setIndexPage, projectAndGrade, loading, setLoading }) => {
  const defaultObject = {
    competencia: "",
    showPosition: "",
    notaMaxima: "",
    countNotas: "",
    exibirPropostaIntervencao: false,
    digitarObservacao: false,
    padronizarObservacao: false,
    observacaoApontamentos: [],
    competenciaId: "",
  };
  const [skillData, setSkillData] = useState([defaultObject]);
  const [openModal, setOpenModal] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);

  useEffect(() => {
    const loadSkills = async () => {
      try {
        if (projectAndGrade && projectAndGrade.data[0]) {
          const response = await Axios.get(
            `${API_HOST}/grades/?projeto=${projectAndGrade.data[0]}`,
            getAxiosConfig()
          );

          if (Array.isArray(response.data) && response.data.length > 0) {
            const projectData = response.data[0];

            if (projectData && Array.isArray(projectData.competencias)) {
              const competencias = projectData.competencias
                .map((competencia) => {
                  const indicadores =
                    competencia.indicadores && competencia.indicadores[0]
                      ? competencia.indicadores[0]
                      : null;

                  if (!indicadores) {
                    console.warn(
                      `Indicadores não encontrados para a competência: ${competencia.nome}`
                    );
                    return null;
                  }

                  const alternativas = indicadores.alternativas || [];
                  const setasDesvio = indicadores.setas_desvio || [];

                  const observacaoApontamentos = setasDesvio.reduce(
                    (acc, seta) => {
                      if (seta.observacoes) {
                        acc = acc.concat(
                          seta.observacoes.map((obs) => obs.text)
                        );
                      }
                      return acc;
                    },
                    [],
                  );

                  const digitarObservacao = setasDesvio.some(
                    (seta) => seta.permite_editar_observacoes
                  );
                  const padronizarObservacao = setasDesvio.some(
                    (seta) => seta.setas_observacao
                  );

                  const notasObj = alternativas.reduce((acc, alt, index) => {
                    acc[`nota${index}`] = alt.descricao;
                    return acc;
                  }, {});

                  return {
                    competencia: competencia.nome,
                    showPosition: competencia.posicao,
                    notaMaxima: parseInt(indicadores.total_pontos),
                    countNotas: alternativas.length,
                    exibirPropostaIntervencao:
                      competencia.habilitar_area_de_observacao,
                    ...notasObj,
                    digitarObservacao: digitarObservacao,
                    padronizarObservacao: padronizarObservacao,
                    observacaoApontamentos: padronizarObservacao ? observacaoApontamentos : [],
                    competenciaId: competencia.id,
                  };
                })
                .filter(Boolean);

              setSkillData(
                competencias.length > 0 ? competencias : [defaultObject]
              );
            } else {
              console.error(
                "Dados de competências não encontrados ou não são um array."
              );
              setSkillData([defaultObject]);
            }
          } else {
            console.error(
              "Resposta inesperada da API. Não contém o array esperado."
            );
            setSkillData([defaultObject]);
          }
        }
      } catch (error) {
        console.error("Erro ao carregar as competências:", error);
        toast.error("Erro ao carregar as competências.");
      } finally {
        setLoading(false);
      }
    };

    loadSkills();
  }, [projectAndGrade]);

  const handleBack = (event) => {
    setIndexPage(2);
  };
  const handleAdd = (event) => {
    if (skillData.length < 5) {
      const updatedSkillData = [...skillData];
      updatedSkillData.push(defaultObject);

      setSkillData(updatedSkillData);
    } else {
      toast.error("O máximo de competências por projeto já foi atigindo!");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Validar os dados antes de enviar
    const validationErrors = validateSkillsData(skillData);
    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => toast.error(error));
      setLoading(false);
      return;
    }

    try {
      event.preventDefault();
      await Axios.post(
        `${API_HOST}/grades/create_competencia_from_educat/${projectAndGrade.data[1]}/createcompetencia`,
        skillData,
        getAxiosConfig()
      );
      toast.success("Compêtencias criadas com sucesso.");
      window.location.href = "/list-projects";
    } catch (error) {
      if (error.response && error.response.status === 404)
        toast.error(error.response.data);
      else {
        toast.error("Algo inesperado aconteceu.");
        console.error("error", error);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteModal = (indexFromDelete) => {
    setOpenModal(true);
    setQuestionIndex(indexFromDelete);
  };

  const handleDeleteAction = async (skill) => {
    setSkillData((prevSkillData) =>
      prevSkillData.filter((_, index) => index !== questionIndex)
    );

    const competencia_id = skill && skill.competenciaId;

    try {
      const response = await Axios.get(
        `${API_HOST}/grades/competencias/${competencia_id}`,
      );
      if (response.status === 200) {
        await Axios.delete(
          `${API_HOST}/grades/competencias/${competencia_id}`,
        );
      }
      toast.success("Habilidade excluida com sucesso!");
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        toast.error("Algo inesperado aconteceu.");
        console.error("Error:", error);
      }
    } finally {
      setOpenModal(false);
    }
  } 

  if (loading) {
    return (
      <Skeleton height={900} width={966} borderRadius={8} />
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {skillData.map((data, index) => (
          <SkillsForProject
            key={index}
            setIndexPage={setIndexPage}
            skillData={skillData}
            data={data}
            setSkillData={setSkillData}
            handleSubmit={handleSubmit}
            handleDeleteSkill={handleDeleteModal}
            index={index}
          />
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            color: "#218EC6",
            cursor: "pointer",
          }}
          onClick={() => handleAdd()}
        >
          <StyledEnunciado style={{ color: "#218EC6" }}>
            Adicionar Competência
          </StyledEnunciado>
        </div>
        <StyledBoxButtons>
          <Button variant="outlined" color="primary" onClick={handleBack}>
            Voltar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Finalizar criação de projeto
          </Button>
        </StyledBoxButtons>
      </form>
      {openModal && (
        <ModalOverlay>
          <ExcludeSkillModal>
            <p>Tem certeza que deseja excluir o item {questionIndex + 1}?</p>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleDeleteAction(skillData[questionIndex])}
            >
              Confirmar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(false)}
            >
              Cancelar
            </Button>
          </ExcludeSkillModal>
        </ModalOverlay>
      )}
    </div>
  );
};

export default CreateSkillsForProject;
